.formulate-form {
  &.formulate-form-grid,
  .formulate-form-grid {
    @apply grid grid-cols-12 gap-3;
    @apply xs:gap-5;
    @apply lg:gap-6;
    @apply gap-x-4 gap-y-0;

    .formulate-input {
      &[data-classification='text'],
      &[data-classification='textarea'],
      &[data-classification='select'],
      &[data-classification='box'] {
        @apply relative pt-[9px] mb-[19px];
      }
    }

    .formulate-input {
      &[data-classification='box'] {
        @apply relative;
      }
    }
  }

  .formulate-input {
    &[data-classification='text'],
    &[data-classification='textarea'],
    &[data-classification='select'] {
      .formulate-input-wrapper {
        @apply relative w-full;

        .formulate-input-element {
          @apply w-full z-1;

          input,
          textarea,
          select {
            @apply w-full u-body-md px-3 py-3 border-1 border-primary rounded-lg transition-all outline-none;
          }
        }
      }
    }

    &[data-classification='text'] {
      .formulate-input-wrapper {
        .formulate-input-element {
          input {
            @apply h-12;
          }
        }

        label {
          @apply absolute top-1/2 -translate-y-1/2 left-2 px-1 transition-all u-body-md bg-white z-2;
        }
      }
    }

    &[data-classification='textarea'] {
      .formulate-input-wrapper {
        .formulate-input-element {
          textarea {
            @apply h-24 resize-none overflow-scroll;
          }
        }

        label {
          @apply absolute top-0 -translate-y-1/2 left-2 px-1 transition-all u-body-sm bg-white z-2;
        }
      }
    }

    &[data-classification='select'] {
      .formulate-input-wrapper {
        &::after {
          @apply content-blank absolute top-1/2 right-3 w-6 h-6 -translate-y-1/2 bg-contain;
          background: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E %3Cpath%20d%3D%22M20.0388%209.26928L12.2695%2017.0386L4.50025%209.26928%22%20stroke%3D%22%230B2537%22%20stroke-width%3D%222%22%2F%3E %3C%2Fsvg%3E');
        }

        label {
          @apply absolute top-1/2 -translate-y-1/2 left-2 px-1 transition-all u-body-md bg-white z-2 pointer-events-none;
        }

        .formulate-input-element {
          select {
            @apply appearance-none bg-white;

            &[data-option-disabled='true'] {
              @apply text-white;

              option {
                @apply text-initial font-sansSerif;
              }
            }
          }
        }
      }
    }

    &[data-classification='box'] {
      &[data-type='checkbox'] {
        @apply inline-block mb-6;

        &.half-margin {
          @apply mb-3;
        }

        .formulate-input-wrapper {
          @apply flex flex-row justify-start;

          .formulate-input-element {
            input {
              @apply relative w-6 h-6 bg-white appearance-none cursor-pointer;

              &::before {
                @apply content-blank absolute w-full h-full top-0 left-0 border-2 border-primary rounded-sm transition-colors;
              }
            }
          }

          label {
            span {
              &:first-of-type {
                @apply cursor-pointer;
              }
            }
          }
        }
      }
    }

    &[data-classification='button'] {
      @apply p-0 m-0;

      .formulate-input-wrapper {
        .formulate-input-element {
          button {
            // @apply relative inline-flex flex-nowrap items-center focus:outline-none bg-primary-400 hover:bg-white text-grey-900 rounded-lg h-12 px-4 py-3 border-2 border-solid border-primary-400 transition-colors duration-200 ease-in-out;
            @apply relative inline-flex flex-nowrap items-center focus:outline-none hover:bg-white hover:text-grey-900 rounded-lg h-12 px-4 py-3 border-2 border-solid transition-colors duration-200 ease-in-out;

            &::after {
              @apply content-blank absolute -inset-1.5 opacity-0 border-2 border-grey-900 border-solid rounded-lg transition-opacity duration-200 ease-in-out pointer-events-none;
            }

            span {
              @apply u-body-md font-bold border-b-2 border-solid border-transparent transition-colors duration-200 ease-in-out;
            }
          }
        }
      }
    }

    //========================================================================||
    //	Disabled
    //========================================================================||
    &.is-disabled {
      @apply pointer-events-none cursor-not-allowed select-none;

      &[data-classification='text'],
      &[data-classification='textarea'] {
        .formulate-input-wrapper {
          @apply bg-white;

          label {
            @apply text-grey-300 bg-white;
          }

          .formulate-input-element {
            input,
            textarea {
              @apply text-grey-300 border-1 border-grey-300 bg-white;

              &::placeholder {
                @apply text-grey-300;
              }
            }
          }
        }

        &[data-is-showing-errors] {
          .formulate-input-wrapper {
            label {
              @apply text-grey-300;
            }

            .formulate-input-element {
              input,
              textarea {
                @apply text-grey-300 border-1 border-grey-300;
              }
            }
          }
        }
      }

      &[data-classification='select'] {
        .formulate-input-wrapper {
          @apply bg-white;

          label {
            @apply text-grey-300;
          }

          &::after {
            @apply opacity-30;
          }

          .formulate-input-element {
            select {
              @apply text-grey-300 border-1 border-grey-300;

              &[data-option-disabled='true'] {
                @apply text-white;

                option {
                  @apply text-initial font-sansSerif;
                }
              }
            }
          }
        }

        &[data-is-showing-errors] {
          .formulate-input-wrapper {
            label {
              @apply text-grey-300;
            }

            .formulate-input-element {
              select {
                @apply text-grey-300 border-1 border-grey-300;
              }
            }
          }
        }
      }

      &[data-classification='button'] {
        .formulate-input-wrapper {
          .formulate-input-element {
            button {
              @apply bg-grey-200 text-grey-600 border-0;
            }
          }
        }
      }

      &[data-classification='box'] {
        &[data-type='checkbox'] {
          .formulate-input-wrapper {
            .formulate-input-element {
              input {
                &::before {
                  @apply border-grey-300;
                }
              }
            }

            .formulate-input-label {
              @apply text-grey-300;
            }
          }
        }
      }
    }

    //========================================================================||
    //	Hover
    //========================================================================||
    &:hover {
      &[data-classification='text'],
      &[data-classification='textarea'],
      &[data-classification='select'] {
        .formulate-input-wrapper {
          .formulate-input-element {
            input,
            textarea,
            select {
              @apply shadow-why-benefits-card;
            }

            button {
              span {
                @apply border-current;
              }
            }
          }
        }
      }

      &[data-classification='button'] {
        .formulate-input-wrapper {
          .formulate-input-element {
            button {
              span {
                @apply border-current;
              }
            }
          }
        }
      }
    }

    &[data-classification='box'] {
      &[data-type='checkbox'] {
        .formulate-input-wrapper {
          .formulate-input-element {
            &:hover {
              input {
                &::before {
                  @apply bg-grey-200;
                }
              }
            }
          }
        }
      }
    }

    //========================================================================||
    //	Focus
    //========================================================================||
    &.is-focused {
      &[data-classification='text'],
      &[data-classification='textarea'],
      &[data-classification='select'] {
        .formulate-input-wrapper {
          label {
            @apply top-0 u-body-sm;
          }

          .formulate-input-element {
            input,
            textarea,
            select {
              @apply border-2;
            }
          }
        }

        &:hover {
          .formulate-input-wrapper {
            .formulate-input-element {
              input,
              textarea,
              select {
                @apply shadow-none;
              }
            }
          }
        }
      }

      &[data-classification='button'] {
        .formulate-input-wrapper {
          .formulate-input-element {
            button {
              &:focus {
                &::after {
                  @apply opacity-100;
                }
              }
            }
          }
        }
      }
    }

    &.force-focus {
      &[data-classification='text'],
      &[data-classification='textarea'],
      &[data-classification='select'] {
        .formulate-input-wrapper {
          label {
            @apply top-0 u-body-sm;
          }
        }
      }
    }

    //========================================================================||
    //	Filled
    //========================================================================||
    &[data-has-value] {
      &[data-classification='text'],
      &[data-classification='textarea'],
      &[data-classification='select'] {
        .formulate-input-wrapper {
          label {
            @apply top-0 u-body-sm;
          }
        }
      }

      &[data-classification='box'] {
        &[data-type='checkbox'] {
          .formulate-input-wrapper {
            .formulate-input-element {
              input {
                &::after {
                  @apply content-blank absolute w-full h-full top-0 left-0;
                  background: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E %3Cpath%20d%3D%22M8%2012.5L11.5%2015.5L16.5%208%22%20stroke%3D%22%230B2537%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E %3C%2Fsvg%3E');
                }
              }
            }
          }
        }
      }
    }

    //========================================================================||
    //	Error
    //========================================================================||
    &[data-is-showing-errors] {
      .formulate-input-errors {
        @apply absolute -bottom-5 left-0 ml-3 text-xs text-error;

        .formulate-input-error {
          &:not(:first-of-type) {
            @apply hidden;
          }
        }
      }

      &[data-classification='text'],
      &[data-classification='textarea'] {
        .formulate-input-wrapper {
          .formulate-input-element {
            input,
            textarea,
            select {
              @apply border-2 border-error;
            }

            &::after {
              @apply content-blank absolute top-1/2 right-3 w-6 h-6 -translate-y-1/2 bg-contain;
              background: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%2212%22%20fill%3D%22%23DF1642%22%2F%3E%3Cpath%20d%3D%22M12%206V14.5%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3Ccircle%20cx%3D%2212%22%20cy%3D%2218%22%20r%3D%221%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E');
            }
          }
        }

        &:hover {
          .formulate-input-wrapper {
            .formulate-input-element {
              input,
              textarea,
              select {
                @apply shadow-none;
              }
            }
          }
        }
      }

      &[data-classification='select'] {
        .formulate-input-wrapper {
          .formulate-input-element {
            input,
            textarea,
            select {
              @apply border-2 border-error;
            }

            &::after {
              @apply content-blank absolute top-1/2 right-10 w-6 h-6 -translate-y-1/2 bg-contain;
              background: url('data:image/svg+xml,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ccircle%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%2212%22%20fill%3D%22%23DF1642%22%2F%3E%3Cpath%20d%3D%22M12%206V14.5%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3Ccircle%20cx%3D%2212%22%20cy%3D%2218%22%20r%3D%221%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E');
            }
          }
        }

        &:hover {
          .formulate-input-wrapper {
            .formulate-input-element {
              input,
              textarea,
              select {
                @apply shadow-none;
              }
            }
          }
        }
      }

      &[data-classification='box'] {
        &[data-type='checkbox'] {
          .formulate-input-wrapper {
            .formulate-input-element {
              input {
                @apply bg-error bg-opacity-20;

                &::before {
                  @apply border-error;
                }
              }
            }
          }

          .formulate-input-errors {
            @apply absolute -bottom-5 left-6 ml-4 text-xs text-error;

            .formulate-input-error {
              &:not(:first-of-type) {
                @apply hidden;
              }
            }
          }
        }
      }
    }
  }
}
