#kk-read-more a {
  color: #000 !important;
  text-decoration: underline !important;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

#kk-read-more a:hover {
  color: #000 !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

#kk-read-more h2 {
  font-family: Circular Std !important;
  font-size: 32px !important;
  line-height: 1.25 !important;
  font-weight: normal !important;
  margin-bottom: 1.75rem !important;
}

#kk-read-more p {
  font-family: Circular Std !important;
  font-size: 18px !important;
  line-height: 1.25 !important;
  margin: 0px 0px 28px !important;
}

#kk-show-more,
a#kk-hide-less {
  color: #000 !important;
}

.kk-footer-container {
  display: block;
  width: 100%;
}

.kk-footer {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1180px;
  padding: 50px 0px 0px;
}

.kk-footer a {
  text-transform: capitalize;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.kk-footer ul {
  list-style: outside none none;
  margin: 0;
  padding: 0 5px;
}

.kk-footer a:hover {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

@media (max-width: 1023px) {
  .kk-footer {
    padding: 20px 0px;
    text-align: left;
    display: block;
  }
}
