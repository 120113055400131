@font-face {
  font-family: 'Circular Std';
  src: url('../fonts/CircularStd-Bold.woff2') format('woff2'),
    url('../fonts/CircularStd-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Circular Std';
  src: url('../fonts/CircularStd-Book.woff2') format('woff2'),
    url('../fonts/CircularStd-Book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Better Times';
  src: url('../fonts/BetterTimes.woff2') format('woff2'),
    url('../fonts/BetterTimes.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// ==========================================================================||
//  Settings
// ==========================================================================||

@import './settings/settings.hamburgers.scss';

// ==========================================================================||
//  Vendors
// ==========================================================================||

@import 'hamburgers/_sass/hamburgers/base';
@import 'hamburgers/_sass/hamburgers/types/elastic';

@import 'swiper/scss';
@import 'swiper/scss/scrollbar';

@import 'plyr/src/sass/plyr.scss';

@import 'js-datepicker/src/datepicker';

// ==========================================================================||
//  Base
// ==========================================================================||

@import 'tailwindcss/base';

// ==========================================================================||
//  Custom Base
// ==========================================================================||
@layers base {
}

// ==========================================================================||
//  Components
// ==========================================================================||

@import 'tailwindcss/components';

// ==========================================================================||
//  Custom Components
// ==========================================================================||
@layer components {
  @import './elements/elements.content.scss';
  @import './elements/elements.content-simple.scss';
  @import './elements/elements.content-media-block.scss';
  @import './elements/elements.content-list.scss';
  @import './elements/elements.hamburgers.scss';

  @import './objects/objects.wrapper.scss';
  @import './objects/objects.grid.scss';

  @import './components/components.carousel.scss';
  @import './components/components.gallery-modal.scss';
  @import './components/components.gallery-pagination.scss';
  @import './components/components.events-swiper.scss';
  @import './components/components.vue-formulate.scss';
  @import './components/components.header-secondary.scss';
  @import './components/components.card.scss';
  @import './components/components.plyr.scss';

  @import './components/components.king-kong.scss';
}

// ==========================================================================||
//  Utilities
// ==========================================================================||

@import 'tailwindcss/utilities';

// ==========================================================================||
//  Custom Utilities
// ==========================================================================||
@layer utilities {
  @import './utilities/_utilities.bg-colors.scss';
  @import './utilities/_utilities.typography.scss';
  @import './utilities/_utilities.misc.scss';
  @import './utilities/_utilities.list-style.scss';

  .is-data-element {
    @apply hidden absolute top-[-9999px] left-[-9999px];
  }
}

@import './utilities/_utilities.vue-animation.scss';

.grecaptcha-badge {
  visibility: hidden;
}