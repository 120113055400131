.content {
  $this: &;
  @apply font-circular text-body text-base leading-base;

  @screen lg {
    @apply leading-lg;
  }

  * {
    &:first-child {
      @apply mt-0;
    }

    &:last-child {
      @apply mb-0;
    }
  }

  // TODO: define heading typography styles here
  // TODO: define heading margin here

  ul,
  ol {
    @apply my-10;

    > li {
      @apply relative my-1 pl-5;
    }
  }

  ol {
    counter-reset: section;

    &[type='A'] {
      --list-counter-style: 'upper-alpha';
    }
    &[type='a'] {
      --list-counter-style: 'lower-alpha';
    }
    &[type='A'] {
      --list-counter-style: 'upper-alpha';
    }
    &[type='a'] {
      --list-counter-style: 'lower-alpha';
    }
    &[type='I'] {
      --list-counter-style: 'upper-roman';
    }
    &[type='i'] {
      --list-counter-style: 'lower-roman';
    }
    &[type='I'] {
      --list-counter-style: 'upper-roman';
    }
    &[type='i'] {
      --list-counter-style: 'lower-roman';
    }
    &[type='1'] {
      --list-counter-style: 'decimal';
    }

    > li {
      &:before {
        @apply text-primary absolute left-0;
        counter-increment: section;
        content: counter(section, var(--list-counter-style, decimal)) '.';
      }
    }
  }

  ul {
    > li {
      &:before {
        @apply content-bullet text-primary absolute left-0;
      }
    }
  }

  p,
  dl,
  ol,
  ul {
    &:not(:first-child) {
      @apply mt-4 lg:mt-10;
    }

    &:not(:last-child) {
      @apply mb-4 lg:mb-10;
    }
  }

  a:not([class]) {
    @apply border-b-2 border-current border-solid transition duration-200 ease-in-out group-hover:border-transparent;
    @apply hover:border-transparent;
  }

  b,
  strong {
    @apply font-bold;
  }

  img {
    @apply block max-w-full h-auto;

    &:not([style*='float']) {
      @apply w-full;
    }
  }

  // img[style*='float: left']:not([style*='margin']) {
  //   margin: 0 sp(3) sp(3) 0;
  // }

  // img[style*='float: right']:not([style*='margin']) {
  //   margin: 0 0 sp(3) sp(3);
  // }

  figure {
    @apply block w-full my-8 mx-auto rounded-lg overflow-hidden;

    @screen lg {
      width: calc(100% + 8rem);
      @apply my-16 -mx-16;
    }

    figcaption {
      @extend .u-body-md;
      @apply text-body mt-4 p-0;
    }
  }

  iframe {
    @apply block w-full max-w-full;
  }

  // hide the rest
  table,
  blockquote,
  pre,
  dl,
  dd,
  sup,
  sub {
    display: none;
  }

  // ==========================================================================||
  //  Variations
  // ==========================================================================||

  // TODO: Tweak element spacing for p, ul, ol etc.

  &-sm {
    @apply text-xs leading-base;
  }

  &-md {
    @apply text-sm leading-md;

    @screen lg {
      @apply leading-lg;
    }
  }

  &-lg {
    @apply text-base leading-base;

    @screen lg {
      @apply leading-lg;
    }
  }

  &-xl {
    @apply text-base leading-base;

    @screen lg {
      @apply text-md leading-lg;
    }
  }
}
