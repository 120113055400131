$hamburger-padding-x: 10px;
$hamburger-padding-y: 12px;
$hamburger-layer-width: 25px;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: #d85300; // color.primary.900
$hamburger-layer-border-radius: 8px;

$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 200ms;
$hamburger-hover-transition-timing-function: ease-in-out;
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(100%);

$hamburger-active-layer-color: #0b2537; // color.primary.navy
$hamburger-active-hover-opacity: 1;
$hamburger-active-hover-filter: opacity(100%);

$hamburger-types: (elastic);
