// TESTIMONIAL TEXT
.testimonial-enter-active,
.testimonial-enter-active img,
.testimonial-enter-active picture {
  @apply transition duration-700;
}

.testimonial-leave-active,
.testimonial-leave-active img,
.testimonial-leave-active picture {
  @apply transition duration-500;
}

.testimonial-enter,
.testimonial-enter img,
.testimonial-enter picture {
  @apply opacity-0 scale-90;
}

.testimonial-enter-to,
.testimonial-enter-to img,
.testimonial-enter-to picture {
  @apply opacity-100;
}

.testimonial-leave,
.testimonial-leave img,
.testimonial-leave picture {
  @apply opacity-100;
}

.testimonial-leave-to,
.testimonial-leave-to img,
.testimonial-leave-to picture {
  @apply opacity-0 translate-y-20;
}

//HOME HERO GALLERY
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 1.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-fade-enter {
  transform: translateX(100%);
  @apply opacity-100;
  position: absolute;
}

.slide-fade-leave-to {
  transform: translateX(-100%);
  @apply opacity-100;
  position: absolute;
}

.slide-fade-reverse-enter-active,
.slide-fade-reverse-leave-active {
  transition: all 1.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-fade-reverse-enter {
  transform: translateX(-100%);
  @apply opacity-100;
  position: absolute;
}

.slide-fade-reverse-leave-to {
  transform: translateX(100%);
  @apply opacity-100;
  position: absolute;
}

.transition-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  &-enter {
    @apply opacity-0;
  }

  &-enter-to {
    @apply opacity-100;
  }

  &-enter-active {
    @apply transition duration-200 ease-in-out;
  }

  &-leave {
    @apply opacity-100;
  }

  &-leave-to {
    @apply opacity-0;
  }

  &-leave-active {
    @apply transition duration-200 ease-in-out;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}
