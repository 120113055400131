.u-bg-gradient-bright-spark {
  background: linear-gradient(
    180deg,
    theme('colors.primary.DEFAULT') 6.77%,
    theme('colors.primary.DEFAULT') 56.25%,
    #ed7200 100%
  );
}

.u-bg-gradient-header-secondary {
  background: linear-gradient(
    270deg,
    theme('colors.secondary.DEFAULT') 24.82%,
    rgba(255, 255, 255, 0) 81.74%
  );
}
