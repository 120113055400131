@keyframes cardAnimation {
  0% {
    transform: translate3d(-30%, 0, 0);
  }
  100% {
    transform: translate3d(30%, 0, 0);
  }
}

.c-card {
  $this: &;

  @apply relative;

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 46%,
        rgba(255, 255, 255, 0.35) 50%,
        rgba(255, 255, 255, 0) 54%
      )
      50% 50%;
    -webkit-animation: cardAnimation 0.8s linear infinite;
    animation: cardAnimation 0.8s linear infinite;
    content: ' ';
    pointer-events: none;
  }
}
