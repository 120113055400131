.content-media-block {
  $this: &;

  h2, h3, h4 {
    @apply mb-5;
  }

  p {
    &:not(:last-child) {
      @apply mb-7;
    }
  }

  ul,
  ol {
    &:not(:last-child) {
      @apply mb-7;
    }

    li {
      @apply relative my-1 pl-5;

      &:not(:last-of-type) {
        @apply mb-3;
      }
    }
  }

  ul {
    li {
      &::before {
        @apply content-bullet text-primary absolute left-0;
      }
    }
  }

  ol {
    counter-reset: section;

    &[type='A'] {
      --list-counter-style: 'upper-alpha';
    }
    &[type='a'] {
      --list-counter-style: 'lower-alpha';
    }
    &[type='A'] {
      --list-counter-style: 'upper-alpha';
    }
    &[type='a'] {
      --list-counter-style: 'lower-alpha';
    }
    &[type='I'] {
      --list-counter-style: 'upper-roman';
    }
    &[type='i'] {
      --list-counter-style: 'lower-roman';
    }
    &[type='I'] {
      --list-counter-style: 'upper-roman';
    }
    &[type='i'] {
      --list-counter-style: 'lower-roman';
    }
    &[type='1'] {
      --list-counter-style: 'decimal';
    }

    > li {
      &:before {
        @apply text-primary absolute left-0;
        counter-increment: section;
        content: counter(section, var(--list-counter-style, decimal)) '.';
      }
    }
  }

  a:not([class]):not([id^="kk"]) {
    @apply border-b-2 border-current border-solid transition duration-200 ease-in-out group-hover:border-transparent;
    @apply hover:border-transparent;
  }
}
