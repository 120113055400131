.o-wrapper {
  $this: &;
  @apply float-none mx-auto w-full px-3;

  @screen xs {
    @apply px-5;
  }

  @screen lg {
    @apply max-w-screen-xl px-6;
  }

  // ==========================================================================||
  //	Flushed o-wrapper only on mobile
  // ==========================================================================||
  &--flush\@mobile-only {
    @apply mobile:px-0;
  }

  // ==========================================================================||
  //	Flushed o-wrapper on mobile + tablet
  // ==========================================================================||
  &--flush\@mobile-and-tablet {
    @apply mobile+tablet:px-0;
  }

  // ==========================================================================||
  //	Flushed o-wrapper on all breakpoint
  // ==========================================================================||
  &--flush {
    @apply px-0 mx-auto;
  }

  &--xl {
    @apply max-w-screen-2-5xl;
  }
}
