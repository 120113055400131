// ==========================================================================||
//	[1] Hamburger overwrites to align hamburger center of box
// ==========================================================================||
.hamburger {
  line-height: 1; // [1]
}
.hamburger-box {
  display: block; // [1]
}
// ==========================================================================||
//	Additional styling
// ==========================================================================||
